import create from "zustand";
import { getFromLS, removeFromLS, setInLS } from "../localStorage";
import { persist, StateStorage } from "zustand/middleware";

let utilityImport = null;
const getUtility = async () => {
  if (!utilityImport) utilityImport = await import("@flash-hros/utility");
  return utilityImport;
};
export interface IPermissionsFlags {
  flags: {
    name: string;
    enabled: boolean;
  }[];
  flagIsEnabled?(flagName: string): boolean;
}

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    return getFromLS(name);
  },
  setItem: (name: string, value: string): void => {
    setInLS({ key: name, value: value });
  },
  removeItem: (name: string): void => {
    removeFromLS({ key: name });
  },
};

type PermissionsFlagsStoreProps = {
  companyId: string;
  employeeId: string;
  flags?: IPermissionsFlags["flags"];
  flagIsEnabled?: IPermissionsFlags["flagIsEnabled"];
  loading: boolean;
  getPermissions: (
    companyId: string,
    employeeId: string,
    refetch?: boolean
  ) => Promise<any>;
  setPermissions: (permissions?: IPermissionsFlags) => void;
};

export const usePermissionsFlags = create<PermissionsFlagsStoreProps>()(
  persist(
    (set, get) => ({
      companyId: null,
      employeeId: null,
      flags: null,
      flagIsEnabled: null,
      loading: false,
      setPermissions: (permissions: IPermissionsFlags) => {
        set(() => ({
          flags: permissions.flags,
          flagIsEnabled: permissions.flagIsEnabled || get().flagIsEnabled,
        }));
      },
      getPermissions: async (
        companyId: string,
        employeeId: string,
        refetch?: boolean
      ) => {
        const context = get();

        if (
          context.companyId === companyId &&
          context.employeeId === employeeId
        ) {
          return { flags: context.flags, flagIsEnabled: context.flagIsEnabled };
        }

        set({ loading: true, employeeId, companyId });

        try {
          const { getPermissionsFlags } = await getUtility();
          const selectedCompany = getFromLS("selectedCompany");
          const currentCompanyId: string = companyId || selectedCompany?.id;

          const permissions = await getPermissionsFlags(
            currentCompanyId,
            employeeId
          );

          set({
            flags: permissions.flags,
            flagIsEnabled: permissions.flagIsEnabled,
            loading: false,
          });

          return permissions;
        } catch (err) {
          let response = {
            flags: null,
            flagIsEnabled: null,
          };

          if (refetch) {
            response.flags = get().flags;
            response.flagIsEnabled = get().flagIsEnabled;
          }

          set({ loading: false, ...response });

          return response;
        }
      },
    }),
    {
      name: "permissions_flags_store",
      getStorage: () => storage,
    }
  )
);

export default usePermissionsFlags;

import { Auth, Amplify } from "aws-amplify";

import { getFromLS, setInLS, setInSS, removeFromSS } from "./localStorage";

import { cognitoConfig } from "../configs/aws";

import { useAuth } from "./stores";
import { getPeopleContractStatus, getExpenseData } from "./contract";

let utilityImport = null;

const buildEnv =
  process.env.BUILD_ENV === "development" ? "staging" : process.env.BUILD_ENV;

Amplify.configure(cognitoConfig[buildEnv].Auth);

// Amplify.Logger.LOG_LEVEL = "DEBUG";

const getUtility = async () => {
  if (!utilityImport) utilityImport = await import("@flash-hros/utility");
  return utilityImport;
};

interface ObjectLiteral {
  [key: string]: any;
}

interface CognitoSignInProps {
  login: string;
  password: string;
}

export const CognitoSignIn = async ({
  login,
  password,
}: CognitoSignInProps) => {
  try {
    const signIn = await Auth.signIn(login, password, {
      preferredMfa: "SMS_MFA",
    });

    if (signIn?.challengeName) {
      setInSS({
        key: "userMFA",
        value: {
          login,
          password,
          user: {
            userName: signIn?.username,
            session: signIn?.Session,
            challengeName: signIn?.challengeName,
            challengeNumber: (
              signIn?.challengeParam?.CODE_DELIVERY_DESTINATION || ""
            ).replace(/\D/g, ""),
          },
        },
      });

      return signIn;
    }

    const userAuth = {
      username: signIn?.username || "",
      attributes: signIn?.attributes || null,
      tokenId: signIn?.signInUserSession?.idToken?.jwtToken || "",
    };

    setInLS({
      key: "userAuth",
      value: userAuth,
    });

    useAuth.setState({
      cognitoToken: userAuth,
    });

    return signIn;
  } catch (error) {
    throw error;
  }
};

interface CognitoSignUpProps {
  username: string;
  password: string;
  email?: string;
  phone_number: string;
  name: string;
  autoSignIn: boolean;
}

export const CognitoSignUp = async ({
  username,
  password,
  email,
  phone_number,
  name,
  autoSignIn = true,
}: CognitoSignUpProps) => {
  try {
    let attributes: any = {
      name: name,
      phone_number: phone_number,
    };

    if (email) attributes.email = email;

    const signedUp = await Auth.signUp({
      username: username,
      password: password,
      attributes: attributes,
      autoSignIn: {
        enabled: autoSignIn,
      },
    });

    return signedUp;
  } catch (error) {
    throw error;
  }
};

interface CognitoConfirmSignUpProps {
  username: string;
  code: string;
  options: ObjectLiteral;
}

export const CognitoConfirmSignUp = async ({
  username,
  code,
  options,
}: CognitoConfirmSignUpProps) => {
  try {
    const confirmSignUp = await Auth.confirmSignUp(username, code, options);

    return confirmSignUp;
  } catch (error) {
    throw error;
  }
};

interface CognitoResendSignUpProps {
  username: string;
  code: string;
}

export const CognitoResendSignUpCode = async ({
  username,
}: CognitoResendSignUpProps) => {
  try {
    const resendSignUp = await Auth.resendSignUp(username);
    return resendSignUp;
  } catch (error) {
    throw error;
  }
};

interface CognitoUpdateAttributesProps {
  preferred_username?: string;
  email?: string;
}

export const CognitoUpdateAttributes = async (
  props: CognitoUpdateAttributesProps
) => {
  try {
    var auth = await Auth.currentAuthenticatedUser();
    const updated = await Auth.updateUserAttributes(auth, { ...props });
    return updated;
  } catch (error) {
    throw error;
  }
};

interface CognitoForgotPasswordProps {
  login: string;
  recoveryChannel: "email" | "sms";
}

export const CognitoForgotPassword = async ({
  login,
  recoveryChannel,
}: CognitoForgotPasswordProps) => {
  try {
    const forgotPassword = await Auth.forgotPassword(login, {
      recoveryChannel,
    });

    return forgotPassword;
  } catch (error) {
    throw error;
  }
};

interface CognitoForgotPasswordSubmitProps {
  login: string;
  code: string;
  password: string;
}

export const CognitoForgotPasswordSubmit = async ({
  login,
  code,
  password,
}: CognitoForgotPasswordSubmitProps) => {
  try {
    const forgotPassword = await Auth.forgotPasswordSubmit(
      login,
      code,
      password
    );

    return forgotPassword;
  } catch (error) {
    throw error;
  }
};

interface CognitoSignOutProps {
  options?: ObjectLiteral;
}

export const CognitoSignOut = async ({ options }: CognitoSignOutProps) => {
  try {
    const signedOut = await Auth.signOut(options);

    const accessCode = getFromLS("invitationCode");

    const userPreferences = getFromLS("userPreferences");

    localStorage.clear();

    if (accessCode) setInLS({ key: "invitationCode", value: accessCode });
    if (userPreferences)
      setInLS({ key: "userPreferences", value: userPreferences });

    return signedOut;
  } catch (error) {
    throw error;
  }
};

interface CognitoAuthenticatedProps {
  bypassCache?: boolean;
}

export const CognitoAuthenticatedUser = async ({
  bypassCache = true,
}: CognitoAuthenticatedProps) => {
  try {
    var user = await Auth.currentAuthenticatedUser({ bypassCache });

    const userAuth = {
      username: user?.username || "",
      attributes: user?.attributes || null,
      tokenId: user?.signInUserSession?.idToken?.jwtToken || "",
    };

    setInLS({ key: "userAuth", value: userAuth });

    useAuth.setState({
      cognitoToken: userAuth,
    });

    return userAuth;
  } catch (error) {
    throw error;
  }
};

interface CognitoVerifyUserAttributeProps {
  attr: "phone_number" | "email";
}

export const CognitoVerifyUserAttribute = async ({
  attr,
}: CognitoVerifyUserAttributeProps) => {
  try {
    const response = await Auth.verifyCurrentUserAttribute(attr);

    return response;
  } catch (error) {
    throw error;
  }
};

interface CognitoVerifyUserAttributeSubmitProps {
  attr: "phone_number" | "email";
  code: string;
}

export const CognitoVerifyUserAttributeSubmit = async ({
  attr,
  code,
}: CognitoVerifyUserAttributeSubmitProps) => {
  try {
    const response = await Auth.verifyCurrentUserAttributeSubmit(attr, code);

    return response;
  } catch (error) {
    throw error;
  }
};

interface CognitoSetPreferredMFAProps {
  mfaType: "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | "TOTP" | "SMS" | "NOMFA";
}

export const CognitoSetPreferredMFA = async ({
  mfaType,
}: CognitoSetPreferredMFAProps) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const mfa = await Auth.setPreferredMFA(user, mfaType);

    return mfa;
  } catch (err) {
    throw err;
  }
};

interface CognitoConfirmSignInProps {
  user: { userName: string; session: string };
  code: string;
  mfaType: "SMS_MFA" | "SOFTWARE_TOKEN_MFA";
}

export const CognitoConfirmSignIn = async ({
  user,
  code,
  mfaType,
}: CognitoConfirmSignInProps) => {
  try {
    const cognitoUser = (Auth as any).createCognitoUser(user.userName);
    (cognitoUser as any).Session = user.session;

    await Auth.confirmSignIn(cognitoUser, code, mfaType);

    const authenticatedUser = CognitoAuthenticatedUser({ bypassCache: true });

    removeFromSS({ key: "userMFA" });

    return authenticatedUser;
  } catch (err) {
    throw err;
  }
};

export const getMyCompanies = async () => {
  try {
    const { Axios } = await getUtility();

    const { data } = await Axios({
      service: "accessManagement",
      method: "get",
      url: `me/companies`,
      axiosOptions: { cognitoToken: true },
    });

    setInLS({
      key: "user-accesses",
      value: data,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserAccesses = () => {
  const defaultRes = { companies: [] };
  try {
    const data = getFromLS("user-accesses");
    if (!data) return defaultRes;
  } catch {
    return defaultRes;
  }
};

interface getAccessTokenProps {
  companyId: string;
  isAdmin?: boolean;
}

export const getAccessToken = async ({
  companyId,
  isAdmin,
}: getAccessTokenProps) => {
  try {
    const { Axios, parseJWT } = await getUtility();

    const { data } = await Axios({
      service: "accessManagement",
      method: "post",
      url: `tokens`,
      axiosOptions: { cognitoToken: true },
      data: { companyId },
    });

    const parsedToken = parseJWT({ token: data?.data?.accessToken || "" });
    const accessToken = {
      accessToken: data?.data?.accessToken || "",
      company: data?.data?.company || null,
      employeeId: parsedToken?.employeeId || "",
      role: parsedToken?.role || "",
    };

    let contractStatus = {
      status: "signup_done",
      url: "people/acquisition/checkout",
    } as any;
    try {
      contractStatus = await getPeopleContractStatus({
        companyId,
        token: accessToken?.accessToken,
      });
    } catch (err) {
      console.log(err);
    }

    let expenseData = null;
    try {
      expenseData = await getExpenseData();
      setInLS({
        key: `expensesAcceptanceTermsCompanyId-${companyId}`,
        value: expenseData?.contracts,
      });
    } catch (err) {
      console.log(err);
    }

    useAuth.setState({
      accessToken: accessToken,
      contractStatus: contractStatus,
      expenses: {
        contracts: expenseData?.contracts,
        planFeatures: expenseData?.features,
      },
    });

    return data?.data ? accessToken : null;
  } catch (error) {
    console.log(error);
  }
};

interface redeemInvitationProps {
  code: string;
  validationAttribute: string;
}

export const redeemInvitation = async ({
  code,
  validationAttribute,
}: redeemInvitationProps) => {
  try {
    const { Axios } = await getUtility();

    const { data } = await Axios({
      service: "accessManagement",
      method: "put",
      url: `invitations/redeem`,
      axiosOptions: { cognitoToken: true },
      data: { code, validationAttribute },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

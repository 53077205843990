import { setInLS } from "./localStorage";
let utilityImport = null;

const getUtility = async () => {
  if (!utilityImport) utilityImport = await import("@flash-hros/utility");
  return utilityImport;
};

interface getAccessTokenProps {
  employeeId: string;
}

export const getPermissionsByEmployee = async ({
  employeeId,
}: getAccessTokenProps) => {
  try {
    const { Axios } = await getUtility();

    const { data } = await Axios({
      service: "permission",
      method: "get",
      url: `/employees/${employeeId}/permissions`,
    });

    let objectToResponse = {
      menu: [],
      permissions: [],
    };

    const response = data?.value || {};

    if (response.permissions)
      objectToResponse["permissions"] = response.permissions || [];

    return objectToResponse;
  } catch (error) {
    throw error;
  }
};

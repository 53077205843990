import create from "zustand";

import { getFromLS, removeFromLS, setInLS } from "../localStorage";

import { getPermissionsByEmployee } from "../permission";

import { persist, StateStorage } from "zustand/middleware";

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    return getFromLS(name);
  },
  setItem: (name: string, value: string): void => {
    setInLS({ key: name, value: value });
  },
  removeItem: (name: string): void => {
    removeFromLS({ key: name });
  },
};

type PermissionsItemProps = {
  path: string;
  hasMenu: boolean;
  hasHeader: boolean;
};

type PermissionsStoreProps = {
  permissions: PermissionsItemProps[];
  loading: boolean;
  getPermissions: ({ employeeId }: { employeeId: string }) => Promise<any>;
  setPermissions: (permissions: PermissionsItemProps[]) => void;
};

export const usePermissions = create<PermissionsStoreProps>()(
  persist(
    (set, get) => ({
      permissions: [],
      loading: false,
      setPermissions: (permissions: PermissionsItemProps[]) => {
        set(() => ({
          permissions: permissions,
        }));
      },
      getPermissions: async ({
        employeeId,
        refetch = false,
      }: {
        employeeId: string;
        refetch?: boolean;
      }) => {
        set({ loading: true });

        try {
          const permissions = await getPermissionsByEmployee({ employeeId });

          set({
            permissions: permissions.permissions || [],
            loading: false,
          });

          return permissions;
        } catch (err) {
          let response = {
            permissions: [],
          };

          if (refetch) {
            response.permissions = get().permissions || [];
          }

          set({ loading: false, ...response });

          return response;
        }
      },
    }),
    {
      name: "permissions_store",
      getStorage: () => storage,
    }
  )
);

export default usePermissions;

let utilityImport = null;

const getUtility = async () => {
  if (!utilityImport) utilityImport = await import("@flash-hros/utility");
  return utilityImport;
};

interface getAccessTokenProps {
  companyId: string;
  isAdmin?: boolean;
}

export enum EExpenseContractType {
  FLASH_APP_SAAS_SERVICE_CONTRACT = "FLASH_APP_SAAS_SERVICE_CONTRACT",
  FLASH_APP_CONTRACT_CORPORATE_CARD = "FLASH_APP_CONTRACT_CORPORATE_CARD",
}
export interface IExpensesContracts {
  document: EExpenseContractType;
}

export const getPeopleContractStatus = async ({ companyId, token }) => {
  try {
    const { Axios } = await getUtility();

    const {
      data: { value },
    } = await Axios({
      service: "billingManager",
      method: "get",
      url: `/contract-setup/status/${companyId}`,
    });
    return value?.contractStatus;
  } catch (err) {
    throw err;
  }
};

export const getExpenseData = async () => {
  try {
    const { Axios } = await getUtility();
    const { data } = await Axios({
      service: "expenseManagement",
      method: "get",
      url: `/api/v1/users/me/contracts`,
      axiosOptions: {
        timeout: 30000, // 30 seconds
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

import create from "zustand";

import { getFromLS, removeFromLS, setInLS } from "../localStorage";

import { persist, StateStorage } from "zustand/middleware";
import { IExpensesContracts } from "../contract";

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    return getFromLS(name);
  },
  setItem: (name: string, value: string): void => {
    setInLS({ key: name, value: value });
  },
  removeItem: (name: string): void => {
    removeFromLS({ key: name });
  },
};

type ObjectLiteral = {
  [key: string]: any;
};

export type AuthStoreProps = {
  accessToken: {
    accessToken: string;
    company?: {
      id: string;
      name: string;
    };
    employeeId: string;
    role: "ADMIN" | "EMPLOYEE";
  } | null;
  cognitoToken: {
    attributes:
      | (ObjectLiteral & {
          email: string;
          email_verified: boolean;
          name: string;
          phone_number: string;
          phone_number_verified: boolean;
          preferred_username?: string;
          sub: string;
        })
      | null;
    username: string;
    tokenId: string;
  } | null;
  contractStatus: {
    status: "active" | "signup_done" | "new" | "legacy";
    url: string;
  } | null;
  expenses: { contracts: IExpensesContracts[]; planFeatures: string[] };
  setAccessToken: (newItem: AuthStoreProps["accessToken"]) => void;
  setCognitoToken: (newItem: AuthStoreProps["cognitoToken"]) => void;
  setExpenseContracts: (contracts: IExpensesContracts[]) => void;
  setPlanFeatures: (features: string[]) => void;
};

export const useAuth = create<AuthStoreProps>()(
  persist(
    (set, get) => ({
      contractStatus: null,
      expenses: null,
      accessToken: null,
      cognitoToken: null,
      setAccessToken: (newItem: AuthStoreProps["accessToken"]) => {
        set(() => ({
          accessToken: newItem,
        }));
      },
      setCognitoToken: (newItem: AuthStoreProps["cognitoToken"]) => {
        set(() => ({
          cognitoToken: newItem,
        }));
      },
      setExpenseContracts: (contracts: IExpensesContracts[]) => {
        set(() => ({
          expenses: { ...get()?.expenses, contracts },
        }));
      },
      setPlanFeatures: (planFeatures: string[]) => {
        set(() => ({
          expenses: { ...get()?.expenses, planFeatures },
        }));
      },
    }),
    {
      name: "auth_store",
      getStorage: () => storage,
    }
  )
);

export default useAuth;

import { decrypt, encrypt } from "./crypto";

export const getFromLS = (key: string) => {
  const localStorageItem = localStorage.getItem(encrypt(key));
  const value = localStorageItem ? decrypt(localStorageItem) : undefined;
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const setInLS = ({ key, value }) => {
  try {
    localStorage.setItem(encrypt(key), encrypt(JSON.stringify(value)));
  } catch (err) {
    console.log(err);
  }
};

export const removeFromLS = ({ key }) => {
  localStorage.removeItem(encrypt(key));
};

export const getFromSS = (key: string) => {
  const sessionStorageItem = sessionStorage.getItem(encrypt(key));
  const value = sessionStorageItem ? decrypt(sessionStorageItem) : undefined;

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const setInSS = ({ key, value }) => {
  sessionStorage.setItem(encrypt(key), encrypt(JSON.stringify(value)));
};

export const removeFromSS = ({ key }) => {
  sessionStorage.removeItem(encrypt(key));
};
